<template lang="html">
    <div id="content" class="vs-con-loading__container">
      <vx-card title="">
        <div class="mt-5">
            <gmap-map :center="center" :zoom="4" class="map">
              <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false" v-if="markers_online.length > 0">
                <p><strong>{{machine.serial_number}}</strong></p>
                <span v-if="companyName">{{companyName}}</span>
                <vs-row class="mt-3">
                  <vs-col vs-type="flex" vs-justify="between" vs-w="6">
                    <vx-tooltip :text="$t('actions.edit')">
                      <vs-button color="primary" type="flat" icon-pack="feather" icon="icon-edit" @click.native="editMachine(machine)"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip :text="$t('management.logs')">
                      <vs-button color="primary" type="flat" icon-pack="feather" icon="icon-hard-drive" @click.native="goToMachineLogs(machine)"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip :text="$t('transaction.transactions')">
                      <vs-button color="primary" type="flat" icon-pack="feather" icon="icon-dollar-sign" @click.native="filterTransactions(machine)"></vs-button>
                    </vx-tooltip>
                  </vs-col>
                </vs-row>
              </gmap-info-window>
              <gmap-marker v-for="(m, index) in markers_online" :key="index" :position="m.position" :clickable="true" :draggable="false" :icon="{url: 'https://cdn.pixabay.com/photo/2014/04/03/10/03/google-309741_960_720.png', scaledSize: {width: 26, height: 41}}" @click="toggleInfoWindow(m, index)"></gmap-marker>
              
              <gmap-marker v-for="(m, index) in markers_offline" :key="index" :position="m.position" :clickable="true" :draggable="false" @click="toggleInfoWindow(m, index)"></gmap-marker>
            </gmap-map>
        </div>
      </vx-card>
    </div>
</template>

<script>
export default {
  data: () => ({
    infoWinOpen: false,
    infoWindowPos: null,
    infoOptions: {
      pixelOffset: { width: 0, height: -35 },
    },
    center: { lat: -12.82, lng: -53.9 },
    markers_online: [],
    markers_offline: [],
    markers_warning: [],
    companyName: "",
    machine: {},
  }),
  methods: {
    editMachine(machine) {
      this.$router.push({ name: "machine-edit", params: { machine: machine } });
    },
    filterTransactions(machine) {
      this.$router.push({ name: "transaction-deposit", params: { machine: machine } });
    },
    goToMachineLogs(machine){
      this.$router.push({ name: "management-logs", params: { machine: machine } });
    },
    loadData() {
      let self = this;
      let companies = [];

      this.$vs.loading({
        container: "#content",
        scale: 0.5,
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then((data) => {
          companies = data;
          this.$store
            .dispatch("httpRequest", { method: "get", url: "machine/all" })
            .then((data) => {
              self.$vs.loading.close("#content > .con-vs-loading");
              let machines = data;
              self.markers_online = [];

              machines.forEach((machine) => {
                if (machine.lat && machine.long) {
                  if(machine.warning){
                    self.markers_warning.push({
                      position: { lat: machine.lat, lng: machine.long },
                      company: companies.find(
                        (company) => company.id == machine.current_company
                      ).trade,
                      machine: machine,
                    });
                  }else if(machine.online){
                    self.markers_online.push({
                      position: { lat: machine.lat, lng: machine.long },
                      company: companies.find(
                        (company) => company.id == machine.current_company
                      ).trade,
                      machine: machine,
                    });
                  }else{
                    self.markers_offline.push({
                      position: { lat: machine.lat, lng: machine.long },
                      company: companies.find(
                        (company) => company.id == machine.current_company
                      ).trade,
                      machine: machine,
                    });
                  }
                }
              });
            });
        });
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = marker.position;
      this.companyName = marker.company;
      this.machine = marker.machine;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.map {
  width: 100%;
  height: 600px;
}
</style>